<template>
  <div class="container">
    <div class="question default-card mx-auto pb-2 d-flex flex-column">
      <div v-for="img, i in qs.question.images" :key="i">
        <img style="width:100%" :src="img" alt/>
      </div>
      <div class="text font-1 mx-4 my-2">
        <b>{{ qs.question.text }}</b>
      </div>
      <div class="pt-2">
        <div
          class="answer font-1 mx-4 px-4 my-2 py-2"
          v-for="a, i in qs.question.answers"
          :class="answerClass(i)"
          @click.prevent="$emit('answer', i)">
          {{ a }}
        </div>
      </div>
      <div class="btn btn-primary font-1 mx-auto my-2" v-if="isHintButtonShown" @click.prevent="$emit('hint')">
        <b>Подсказка</b>
      </div>
      <div class="hint font-1 mx-4 px-4 mb-2 py-2" v-else-if="isHintShown">
        {{ qs.question.hint }}
      </div>
      <div
        class="btn btn-primary font-1 mx-auto my-2"
        v-if="qs.status != null && !hideNext"
        @click.prevent="$emit('next')">
        <b>Далее</b>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import QuestionState from "../model/QuestionState";

@Component
export default class QuestionBlock extends Vue {
  @Prop({type: Object, required: true})
  qs!: QuestionState

  @Prop({type: Boolean, default: false})
  hideNext!: boolean

  get isHintButtonShown(): Boolean {
    return this.qs.question.hint != null && !this.qs.hintShown && this.qs.status == null
  }

  get isHintShown(): Boolean {
    return this.qs.question.hint != null && (this.qs.hintShown || this.qs.status == "wrong")
  }

  answerClass(i: number) {
    return {
      right: this.qs.selected != null && i == this.qs.question.right_answer,
      wrong: this.qs.selected != null && this.qs.selected != this.qs.question.right_answer && i == this.qs.selected,
      clickable: this.qs.selected == null
    }
  }

  onAnswerClick(i: number) {
    this.$emit('input', i)
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style";

.correct {
  background-color: rgba(71, 255, 0, 0.98);
}

.incorrect {
  background-color: rgba(200, 0, 255, 0.98);
}

.question {
  .text {
    margin-left: 20px;
    margin-right: 20px;
  }

  .answer {
    background-color: #EFEFEF;
    //border-radius: 10px;
    cursor: default;
    @include transition($btn-transition);

    &.clickable {
      &:hover {
        cursor: pointer;
        background-color: #E0E0E0;
      }

      &:active {
        cursor: pointer;
        background-color: #D0D0D0;
      }
    }

    &.right {
      background-color: $primary;
      color: $on-primary;
    }

    &.wrong {
      background-color: $error;
      color: $on-error;
    }
  }

  .hint {
    background-color: rgba($primary, 0.3);
    //border-radius: 10px;
  }
}
</style>
