import VueI18n from "vue-i18n";
import ru from "@/locale/ru";

export default () => new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    'ru': ru,
  }
})
