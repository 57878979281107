<template>
  <ul class="menu nav justify-content-center">
    <li class="nav-item">
      <a class="nav-link" :class="{ active: $route.path === '/' }" href="/">{{ $t('menu.about') }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{ active: $route.path === '/price' }" href="/price">{{ $t('menu.price') }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{ active: $route.path === '/contacts' }" href="/contacts">{{ $t('menu.contacts') }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{ active: $route.path === '/tickets' }" href="/tickets">{{ $t('menu.tickets') }}</a>
    </li>
  </ul>
</template>

<script lang="ts">

import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class MenuView extends Vue {
}

</script>

<style lang="scss" scoped>
@import "src/assets/vars";
@import "~bootstrap/scss/functions";

.nav {
  background-color: $primary;
  color: $on-primary;
  font-size: 14px;
  line-height: 17px;
}

.nav-link {
  color: $on-primary;
  padding: 16px 12px;

  &:hover {
    color: $on-primary;
    background-color: darken($primary, 3);
  }

  &:active {
    color: $on-primary;
    background-color: darken($primary, 5);
  }

  &:focus {
    color: $on-primary;
  }

  &.active {
    font-weight: bold;
  }
}
</style>
