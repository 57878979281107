<template>
  <div class="container pb-5">
    <div class="default-card mx-auto font-1 py-5 d-flex flex-column">
      <div v-if="errorCount > 2" class="text-center"><b>Билет не пройден</b></div>
      <div v-else class="text-center">Поздравляем! Вы успешно прошли билет.</div>
      <div class="text-center mt-2">Ошибок: {{ errorCount }}</div>
      <div
        class="btn btn-primary font-1 mt-5 mx-auto"
        v-if="errorCount > 0 && errorQuestions == null"
        @click="onShowErrors">
        <b>Показать ошибки</b>
      </div>
    </div>
    <div v-if="errorQuestions != null">
      <question-block class="mt-2" v-for="q, i in errorQuestions" :qs="q" :hide-next="true"/>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import QuestionState from "../model/QuestionState";
import QuestionLine from "./QuestionLine.vue";
import QuestionBlock from "./QuestionBlock.vue";
import {Prop} from "vue-property-decorator";

@Component({
  components: {QuestionBlock, QuestionLine}
})
export default class TestResultBlock extends Vue {
  @Prop({type: Array, required: true})
  questions!: QuestionState[]

  errorQuestions: QuestionState[] | null = null

  get errorCount(): number {
    let errorCount = 0
    this.questions.forEach((i) => {
      if (i.status == 'wrong') {
        errorCount += 1
      }
    })
    return errorCount
  }

  onShowErrors() {
    this.errorQuestions = this.questions.filter((q) => q.status == 'wrong')
  }
}
</script>

<style scoped>

</style>
