<template>
  <div class="header">
    <img class="banner d-none d-lg-block" src="/logo.svg"/>
    <div class="middle-part d-flex justify-content-center" style="flex-grow: 1.7">
      <contacts-view/>
    </div>
    <div class="middle-part d-flex justify-content-center" style="flex-grow: 0.8">
      <contacts2-view/>
    </div>
    <div class="middle-part d-flex justify-content-center" style="flex-grow: 0.5">
      <register-view/>
    </div>
    <!--<img class="banner d-none d-lg-block" src="/logo.svg"/>-->
  </div>
</template>

<script lang="ts">

import Vue from "vue";
import Component from "vue-class-component";
import ContactsView from "./ContactsView.vue";
import RegisterView from "./RegisterView.vue";
import Contacts2View from "./Contacts2View.vue";
@Component({
  components: {Contacts2View, RegisterView, ContactsView}
})
export default class HeaderFooterView extends Vue {
}
</script>

<style scoped lang="scss">
@import "src/assets/style";

.banner {
  width: 148px;
  height: 106px;
}

.header {
  background-color: $surface-container-lowest;
  flex-direction: row;
  align-items: center;
  padding: 0 21px;

  @include media-breakpoint-up('md') {
    display: flex;
    height: 149px;
  }
}

.header img {
  display: block;
}

.middle-part {
  @include media-breakpoint-up('md') {
    margin-top: 0;
    margin-bottom: 0;
  }
  margin-top: 24px;
  //flex-grow: 1;
}

.middle-part:nth-last-child(2) {
  @include media-breakpoint-up('md') {
    margin-bottom: 0;
  }
  margin-bottom: 24px;
}

</style>
