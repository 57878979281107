<template>
  <div>
    <div class="title">
      <h1>{{ $t('callback.title') }}</h1>
      <form style="max-width: 500px; margin: 24px auto 48px auto" @submit.prevent="onSubmit">
        <div class="error-alert" v-if="error">
          {{ $t('callback.error') }}
        </div>
        <div class="success-alert" v-if="success">
          {{ $t('callback.success') }}
        </div>
        <div style="position: relative">
          <select ref="select" v-model="form.city" required>
            <option value="" disabled hidden>{{ $t('callback.placeholder.city') }}</option>
            <option value="г. Мариуполь">г. Мариуполь</option>
            <option value="пгт. Володарское">пгт. Володарское</option>
          </select>
          <img @click="$refs.select.click()" style="position: absolute; top: 18px; right: 20px" src="/dropdown.svg">
        </div>
        <input :placeholder="$t('callback.placeholder.name')" required v-model="form.name"/>
        <input :placeholder="$t('callback.placeholder.phone')" required v-model="form.phone"/>
        <input :placeholder="$t('callback.placeholder.contacts')" v-model="form.contacts"/>
        <textarea :placeholder="$t('callback.placeholder.message')" v-model="form.message"/>
        <input type="submit" class="btn btn-primary" :value="$t('callback.submit')">
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";

@Component
export default class CallbackView extends Vue {
  form = {
    city: '',
    name: '',
    phone: '',
    contacts: '',
    message: ''
  }

  error = false
  loading = false
  success = false

  mounted() {
    if (this.$route.hash) {
      switch (this.$route.hash) {
        case '#bMkpp':
          this.form.message = this.$t('callback.baseMessages.bMkpp').toString()
          break
        case '#bAkpp':
          this.form.message = this.$t('callback.baseMessages.bAkpp').toString()
          break
        case '#aMkpp':
          this.form.message = this.$t('callback.baseMessages.aMkpp').toString()
          break
        case '#dop1Mkpp':
          this.form.message = this.$t('callback.baseMessages.dop1Mkpp').toString()
          break
        case '#dop1Akpp':
          this.form.message = this.$t('callback.baseMessages.dop1Akpp').toString()
          break
        case '#dop3Mkpp':
          this.form.message = this.$t('callback.baseMessages.dop3Mkpp').toString()
          break
        case '#dop3Akpp':
          this.form.message = this.$t('callback.baseMessages.dop3Akpp').toString()
          break
        case '#dop5Mkpp':
          this.form.message = this.$t('callback.baseMessages.dop5Mkpp').toString()
          break
        case '#dop5Akpp':
          this.form.message = this.$t('callback.baseMessages.dop5Akpp').toString()
          break
      }
    }
  }

  async onSubmit() {
    if (this.form.name === '' || this.form.phone === '' || this.form.city === '') {
      return
    }

    try {
      this.loading = true
      this.error = false
      this.success = false
      await axios.post('/api/callback', {
        city: this.form.city,
        name: this.form.name,
        phone: this.form.phone,
        contacts: this.form.contacts,
        message: this.form.message,
      })
      this.form.city = ''
      this.form.name = ''
      this.form.phone = ''
      this.form.contacts = ''
      this.form.message = ''
      this.success = true
    } catch (e) {
      this.error = true
    } finally {
       this.loading = false
    }
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
@import "src/assets/vars";

input:not([type=submit]), textarea, select {
  display: block;
  background-color: $surface-container-lowest;
  border: none;
  margin-top: 16px;
  padding: 16px 24px;
  width: 100%;

  &:focus {
    border: none;
  }
}

input:not([type=submit])::placeholder, textarea::placeholder, select::placeholder {
  color: $outline;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:invalid {
  color: $outline;
}

option {
  color: $on-surface;
}

input[type=submit] {
  width: 100%;
  margin-top: 24px;
}

.error-alert {
  background-color: $error-container;
  color: $on-error-container;
  text-align: start;
  font-weight: bold;
  padding: 16px 32px;
}

.success-alert {
  background-color: $primary-container;
  color: $on-primary-container;
  text-align: start;
  font-weight: bold;
  padding: 16px 32px;
}

</style>
