import axios from "axios";
import TicketShort from "@/model/TicketShort";
import Question from "@/model/Question";

export default {
  async getTicketList(): Promise<TicketShort[]> {
    return (await axios.get('/tickets/index.json')).data as TicketShort[]
  },

  async getQuestionList(ticketNum: string): Promise<Question[]> {
    return (await axios.get(`/tickets/${ticketNum}.json`)).data as Question[]
  }
}
