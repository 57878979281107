<template>
  <div class="container">
    <div class="title">
      <h1>{{ $t('price.title') }}</h1>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4" style="margin-top: 24px;">
        <div class="card">
          <div class="card-title">
            {{ $t('price.card.title') }}
          </div>
          <div class="card-content-title">
            {{ $t('price.card.categoryBmkpp') }}
          </div>
          <div class="card-content-subtitle">
            {{ $t('price.card.length12') }}
          </div>
          <ul class="card-list">
            <li>{{ $t('price.card.standard') }}</li>
            <li>{{ $t('price.card.rassr') }}</li>
          </ul>
          <div class="card-price">
            {{ $t('price.card.price45') }}
          </div>
          <a href="/callback#bMkpp">
            <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
              {{ $t('price.card.action') }}
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-top: 24px;">
        <div class="card">
          <div class="card-title">
            {{ $t('price.card.title') }}
          </div>
          <div class="card-content-title">
            {{ $t('price.card.categoryBakpp') }}
          </div>
          <div class="card-content-subtitle">
            {{ $t('price.card.length12') }}
          </div>
          <ul class="card-list">
            <li>{{ $t('price.card.standard') }}</li>
            <li>{{ $t('price.card.rassr') }}</li>
          </ul>
          <div class="card-price">
            {{ $t('price.card.price50') }}
          </div>
          <a href="/callback#bAkpp">
            <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
              {{ $t('price.card.action') }}
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-top: 24px;">
        <div class="card">
          <div class="card-title">
            {{ $t('price.card.title') }}
          </div>
          <div class="card-content-title">
            {{ $t('price.card.categoryAmkpp') }}
          </div>
          <div class="card-content-subtitle">
            {{ $t('price.card.length10') }}
          </div>
          <ul class="card-list">
            <li>{{ $t('price.card.standard') }}</li>
            <li>{{ $t('price.card.rassr') }}</li>
          </ul>
          <div class="card-price">
            {{ $t('price.card.price35') }}
          </div>
          <a href="/callback#aMkpp">
            <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
              {{ $t('price.card.action') }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <h2>Дополнительные занятия с инструктором на нашем автомобиле</h2>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4" style="margin-top: 24px;">
        <div class="card">
          <div class="card-title">
            {{ $t('price.card_dop.one.title') }}
          </div>
          <div class="row">
            <div class="col-6" style="margin-left: auto; margin-right: auto; text-align: center; padding-right: 0">
              <div class="card-content-title">
                {{ $t('price.card_dop.mkpp') }}
              </div>
              <div class="card-content-subtitle" style="margin-left: auto; margin-right: auto; text-align: center">
                {{ $t('price.card_dop.one.priceMkpp') }}
              </div>
              <a href="/callback#dop1Mkpp">
                <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
                  {{ $t('price.card.action') }}
                </div>
              </a>
            </div>
            <div class="col-6" style="margin-left: 0; margin-right: 0; padding-left: 0">
              <div class="card-content-title" style="margin-left: auto; margin-right: auto; text-align: center;">
                {{ $t('price.card_dop.akpp') }}
              </div>
              <div class="card-content-subtitle" style="margin-left: auto; margin-right: auto; text-align: center;">
                {{ $t('price.card_dop.one.priceAkpp') }}
              </div>
              <a href="/callback#dop1Akpp">
                <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
                  {{ $t('price.card.action') }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-top: 24px;">
        <div class="card">
          <div class="card-title">
            {{ $t('price.card_dop.three.title') }}
          </div>
          <div class="row">
            <div class="col-6" style="margin-left: auto; margin-right: auto; text-align: center; padding-right: 0">
              <div class="card-content-title">
                {{ $t('price.card_dop.mkpp') }}
              </div>
              <div class="card-content-subtitle" style="margin-left: auto; margin-right: auto; text-align: center">
                {{ $t('price.card_dop.three.priceMkpp') }}
              </div>
              <a href="/callback#dop3Mkpp">
                <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
                  {{ $t('price.card.action') }}
                </div>
              </a>
            </div>
            <div class="col-6" style="margin-left: 0; margin-right: 0; padding-left: 0">
              <div class="card-content-title" style="margin-left: auto; margin-right: auto; text-align: center;">
                {{ $t('price.card_dop.akpp') }}
              </div>
              <div class="card-content-subtitle" style="margin-left: auto; margin-right: auto; text-align: center;">
                {{ $t('price.card_dop.three.priceAkpp') }}
              </div>
              <a href="/callback#dop3Akpp">
                <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
                  {{ $t('price.card.action') }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-top: 24px;">
        <div class="card">
          <div class="card-title">
            {{ $t('price.card_dop.five.title') }}
          </div>
          <div class="row">
            <div class="col-6" style="margin-left: auto; margin-right: auto; text-align: center; padding-right: 0">
              <div class="card-content-title">
                {{ $t('price.card_dop.mkpp') }}
              </div>
              <div class="card-content-subtitle" style="margin-left: auto; margin-right: auto; text-align: center">
                {{ $t('price.card_dop.five.priceMkpp') }}
              </div>
              <a href="/callback#dop5Mkpp">
                <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
                  {{ $t('price.card.action') }}
                </div>
              </a>
            </div>
            <div class="col-6" style="margin-left: 0; margin-right: 0; padding-left: 0">
              <div class="card-content-title" style="margin-left: auto; margin-right: auto; text-align: center;">
                {{ $t('price.card_dop.akpp') }}
              </div>
              <div class="card-content-subtitle" style="margin-left: auto; margin-right: auto; text-align: center;">
                {{ $t('price.card_dop.five.priceAkpp') }}
              </div>
              <a href="/callback#dop5Akpp">
                <div class="btn btn-secondary" style="margin-top: 24px; width: 100%; display: block">
                  {{ $t('price.card.action') }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p>
        Дополнительный накат в автошколе - это дополнительные занятия по вождению, которые проводятся после основного
        курса обучения. Они могут включать в себя дополнительные часы за рулем, обучение на разных типах дорог и в
        разных погодных условиях, а также отработку сложных маневров. Цель доп накатки в автошколе - помочь ученику
        стать более уверенным и опытным водителем. Если Вы хотите восстановить или подтянуть навыки водительского
        мастерства - оставьте заявку и менеджер с удовольствием подберет для Вас подходящий курс!
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class PriceView extends Vue {
}
</script>

<style lang="scss" scoped>
@import "src/assets/vars";

.card {
  background-color: $surface-container-highest;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.498);
  border-radius: 0;
  border: none;
  padding: 0;
  text-align: start;
}

.card-title {
  background-color: $primary;
  padding: 14px 24px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: $on-primary;
  margin-bottom: 0;
}

.card-content-title {
  color: $secondary;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  margin: 24px 24px 0 24px;
}

.card-content-subtitle {
  margin: 4px 24px 0 24px;
  color: $primary;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}

ul.card-list {
  color: $on-surface-variant;
  list-style-type: none;
  margin: 24px 24px 0 24px;
}

ul.card-list > li {
  text-indent: -16px;
  padding-left: 0;
  line-height: 17px;
  font-size: 14px;
  margin-top: 8px;
}

ul.card-list > li:first-child {
  margin-top: 0;
}

ul.card-list > li:before {
  content: '—';
  text-indent: -16px;
  display: inline-block;
  margin-right: 16px;
  padding-left: 0;
}

.card-price {
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  color: $secondary;
  text-align: center;
  margin-top: 24px;
}

p, h2 {
  margin: 24px 0 0 0;
  color: $on-surface;
  text-align: justify;
}
p {
  font-size: 14px;
}

p:last-child {
  margin: 24px 0 48px 0;
}
</style>
