<template>
  <div>
    <div>
      <h1 class="title">Билет №{{ ticketNum }}</h1>
    </div>
    <div class="container" style="margin-top: 10px;">
      <div v-if="error" class="loading-or-error-block font-1">
        <div class="text-center">Не удалось загрузить билет</div>
        <div class="btn btn-primary mt-5"><b>Попробовать снова</b></div>
      </div>
      <div v-else-if="errorNotFound" class="loading-or-error-block font-1">
        <div class="text-center">Билет не найден</div>
      </div>
      <div v-else-if="loading" class="loading-or-error-block font-1">
        <div class="text-center">Загрузка</div>
      </div>
      <div v-else-if="!isDone">
        <question-line :question-states="questions" :current="current" @click="onQuestionClick"/>
        <question-block
          class="mt-3 mb-5"
          v-if="currentQuestion != null"
          :qs="currentQuestion"
          @next="onNextClick"
          @answer="onAnswerClick"
          @hint="onHintClick"/>
      </div>
      <div v-else>
        <test-result-block :questions="questions"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import {isNotFoundError} from "../utils/utils";
import QuestionState from "../model/QuestionState";
import api from "../api";
import TestResultBlock from "../components/TestResultBlock.vue";
import QuestionLine from "../components/QuestionLine.vue";
import QuestionBlock from "../components/QuestionBlock.vue";

@Component({
  components: {TestResultBlock, QuestionLine, QuestionBlock}
})
export default class TicketPage extends Vue {
  loading: boolean = false
  error: boolean = false
  errorNotFound: boolean = false
  questions: QuestionState[] = []
  current: number | null = null
  isDone: boolean = false

  mounted() {
    this.loadQuestions()
  }

  get ticketNum(): string {
    return this.$route.params.id
  }

  get currentQuestion(): QuestionState | null {
    if (this.current == null || this.current < 0 || this.current >= this.questions.length) return null
    return this.questions[this.current]
  }

  async loadQuestions() {
    try {
      this.loading = true
      this.error = false
      this.errorNotFound = false
      this.questions = (await api.getQuestionList(this.ticketNum))
        .map((e, i) => {
          return {
            question: e,
            //selected: i > 16 ? e.right_answer == 0 ? 1 : 0 : e.right_answer,
            //status: i > 16 ? 'wrong' : 'right',
            selected: null,
            status: null,
            hintShown: false
          }
        })
      if (this.questions.length > 0) {
        this.current = 0
      } else {
        this.current = null
        this.errorNotFound = true
      }
    } catch (e) {
      if (isNotFoundError(e)) this.errorNotFound = true
      else this.error = true
    } finally {
      this.loading = false
    }
  }

  onQuestionClick(i: number) {
    this.current = i
  }

  onAnswerClick(i: number) {
    let currentQuestion = this.currentQuestion
    if (currentQuestion == null) return
    if (currentQuestion.status != null) return

    currentQuestion.status = i == currentQuestion.question.right_answer ? 'right' : 'wrong'
    currentQuestion.selected = i

    if (currentQuestion.status == 'right') {
      if (this.current != null && this.current < this.questions.length - 1) {
        this.current = this.current + 1
      } else {
        this.isDone = true
      }
    }
  }

  onHintClick() {
    let currentQuestion = this.currentQuestion
    if (currentQuestion != null) {
      currentQuestion.hintShown = true
    }
  }

  onNextClick() {
    if (this.current == null) return
    if (this.current < this.questions.length - 1) {
      this.current = this.current + 1
    } else {
      this.isDone = true
    }
  }
}

</script>

<style scoped>

</style>
