<template>
  <div>
    <div>
      <h1 class="title">Билеты</h1>
    </div>
    <div class="container">
      <ticket-list style="margin-top: 10px"/>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import TicketList from "../components/TicketList.vue";

@Component({
  components: {TicketList}
})
export default class TestsPage extends Vue {
}
</script>

<style lang="scss" scoped>
@import "src/assets/vars";

p, h2 {
  margin: 24px 0 0 0;
  color: $on-surface;
  text-align: justify;
}

a {
  color: $primary;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: darken($primary, 5);
  }

  &:active {
    color: darken($primary, 10);
  }
}

p {
  font-size: 14px;
}

p:last-child {
  margin: 24px 0 48px 0;
}
</style>
