<template>
  <div class="container">
    <div class="title">
      <h1>{{ $t('contacts.title') }}</h1>
    </div>
    <contacts-view style="margin: 24px auto"/>
    <contacts2-view style="margin: 24px auto"/>
    <register-view style="margin: 24px auto"/>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import ContactsView from "./ContactsView.vue";
import RegisterView from "./RegisterView.vue";
import Contacts2View from "./Contacts2View.vue";
@Component({
  components: {RegisterView, ContactsView, Contacts2View}
})
export default class ContactsPage extends Vue {
}

</script>

<style scoped>

</style>
