<template>
  <div class="container">

    <div v-if="error" class="loading-or-error-block font-1">
      <div class="text-center">Не удалось загрузить список билетов</div>
      <div class="btn btn-primary mt-5" @click="reload"><b>Попробовать снова</b></div>
    </div>
    <div v-else-if="loading" class="loading-or-error-block font-1">
      <div class="text-center">Загрузка...</div>
    </div>
    <div class="d-flex flex-wrap justify-content-center" v-else>
      <div v-for="t, i in tickets" :key="i">
        <router-link :to="`/ticket/${t.name}`">
          <div class="ticket-button font-1"><b>{{ t.name }}</b></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";
import TicketShort from "../model/TicketShort";
import api from "../api";

@Component
export default class TicketList extends Vue {
  loading: boolean = false
  error: boolean = false
  tickets: TicketShort[] = []

  mounted() {
    this.reload()
  }

  async reload() {
    try {
      this.loading = true
      this.error = false
      this.tickets = await api.getTicketList()
    } catch (e) {
      this.error = true
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style";

.ticket-button {
  width: 48px;
  padding: 15px 0;
  background-color: $primary;
  color: $on-primary;
  text-align: center;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
  @include transition($btn-transition);

  &:hover {
    background-color: darken($primary, 4);
  }

  &:active {
    background-color: $primary;
  }
}

</style>
