<template>
  <div style="width: 200px">
    <a href="/callback">
      <div class="btn btn-primary" style="width: 100%">{{ $t('register.btn') }}</div>
    </a>
    <div class="description">{{ $t('register.description') }}</div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class RegisterView extends Vue {
}
</script>

<style lang="scss" scoped>
@import "src/assets/vars";

.description {
  color: $on-surface;
  line-height: 17px;
  font-size: 14px;
  text-align: justify;
  margin: 16px 0 0 0;
}
</style>
