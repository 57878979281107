<template>
<table>
  <tr>
    <th>{{ $t('header.address.label') }}</th>
    <td>{{ $t('header.address.value') }}</td>
  </tr>
  <tr>
    <th>{{ $t('header.email.label') }}</th>
    <td>{{ $t('header.email.value') }}</td>
  </tr>
  <tr>
    <th>{{ $t('header.phone1.label') }}</th>
    <td><a :href="`tel:${$t('header.phone1.value')}`">{{ $t('header.phone1.value') }}</a> <a :href="`https://t.me/${$t('header.phone1.value')}`"><img src="/telegram.svg"/></a> <a :href="`https://wa.me/${$t('header.phone1.value')}`"><img src="/whatsapp.svg"/></a></td>
  </tr>
  <tr>
    <th>{{ $t('header.phone2.label') }}</th>
    <td><a :href="`tel:${$t('header.phone1.value')}`">{{ $t('header.phone2.value') }}</a> <a :href="`https://t.me/${$t('header.phone2.value')}`"><img src="/telegram.svg"/></a> <a :href="`https://wa.me/${$t('header.phone2.value')}`"><img src="/whatsapp.svg"/></a></td>
  </tr>
  <tr>
    <th><img src="/vk.svg"/></th>
    <td><a :href="`https://${$t('header.vk.value')}`">{{ $t('header.vk.value') }}</a></td>
  </tr>
</table>
</template>

<script>
export default {
  name: "ContactsView"
}
</script>

<style scoped>

table {
  line-height: 17px;
  font-size: 12px;
}

table th {
  width: 150px;
  padding: 0 14px;
  text-align: end;
  font-weight: normal;
}

table td {
  width: 300px;
  font-weight: bold;
}

a {
  text-decoration: none;
}

</style>
