<template>
  <div>
    <div class="title">
      <h1>{{ $t('about.title') }}</h1>
    </div>
    <div class="container">
      <h2>ООО "РЕСПУБЛИКАНСКАЯ АВТОШКОЛА"</h2>
      <p style="margin-bottom: 8px">
        Курсы вождения на автомобиле и мотоцикле - стань профи, получи права с нами!<br/>
        Обучение водителей категории А, А1, В, В1, М<br/>
        Оборудованные учебные классы<br/>
        Практика по экзаменационным маршрутам ГИБДД<br/>
      </p>
      <a href="/lic.pdf">Лицензия</a>
      <h2>Республиканская автошкола ООО "РАШ" - двигает мир вперед - быстро, надежно и естественно устойчиво.</h2>
      <p>
        Автошкола РАШ имеет современное оборудование, новые автомобили и опытных инструкторов, которые помогут вам
        пройти через все этапы обучения. Мы стремимся к тому, чтобы каждый курсант чувствовал себя комфортно и
        уверенно во время обучения, поэтому мы предлагаем индивидуальный подход и гибкое расписание.
      </p>
      <p>
        Наши курсы вождения включают в себя теоретические занятия, на которых вы изучите правила дорожного движения
        и основы безопасности на дороге, а также практические занятия, где вы научитесь управлять автомобилем в
        различных ситуациях на дороге и отрабатывать навыки парковки. После успешного завершения обучения в автошколе
        РАШ, вы получите все необходимые знания и навыки для безопасного вождения автомобиля и станете уверенным
        водителем. Мы гордимся тем, что наши выпускники успешно сдают экзамены в ГИБДД и становятся ответственными
        участниками дорожного движения.
      </p>
      <p>
        Республиканская автошкола  предлагает высококачественное обучение вождению на категорию А,А1,В,В1,М Для
        начинающих водителей. Наша школа была основана с целью предоставления качественного и безопасного обучения
        вождению, что позволит нашим курсантам стать уверенными и ответственными водителями. Наши опытные инструкторы
        обучат Вас всем необходимым навыкам и знаниям, чтобы вы чувствовали себя комфортно и безопасно на дороге.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import HeaderFooterView from "@/components/HeaderFooterView.vue";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  components: {
    HeaderFooterView,
    HelloWorld
  }
})
export default class HomeView extends Vue {
}
</script>

<style scoped lang="scss">
@import "src/assets/vars";

p, h2 {
  margin: 24px 0 0 0;
  color: $on-surface;
  text-align: justify;
}

a {
  color: $primary;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: darken($primary, 5);
  }

  &:active {
    color: darken($primary, 10);
  }
}

p {
  font-size: 14px;
}

p:last-child {
  margin: 24px 0 48px 0;
}
</style>
