<template>
  <div id="app">
    <div class="header-logo d-flex d-lg-none justify-content-center align-items-center">
      <img class="banner" src="/logo.svg"/>
    </div>
    <header-footer-view class="d-none d-lg-flex"/>
    <menu-view/>
    <div style="flex-grow: 1">
      <router-view/>
    </div>
    <header-footer-view/>
  </div>
</template>

<style lang="scss">
@import "src/assets/vars";

#app {
  font-family: Verdana, Ubuntu, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $on-surface;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.banner {
  width: 163px;
  height: 117px;
  margin: auto;
}

.header-logo {
  background-color: $surface-container-lowest;
  height: 149px;
}

</style>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import HeaderFooterView from "./components/HeaderFooterView.vue";
import MenuView from "./components/MenuView.vue";

@Component({
  components: {MenuView, HeaderFooterView}
})
export default class App extends Vue {
}
</script>
