export default {
  header: {
    address: {
      label: 'Учебный класс',
      value: 'г.Мариуполь, 7 проезд 8, этаж 2',
    },
    address2: {
      label: 'Учебный класс',
      value: 'пгт. Володарское, ул. Советская, д. 62А, этаж 3',
    },
    email: {
      label: 'E-mail',
      value: 'info@ras180.ru'
    },
    phone1: {
      label: 'Телефон 1',
      value: '+79495005959'
    },
    phone2: {
      label: 'Телефон 2',
      value: '+79333337474'
    },
    phone3: {
      label: 'Телефон',
      value: '+79495903500'
    },
    vk: {
      value: 'vk.com/ras180'
    }
  },
  register: {
    btn: 'Оставить заявку',
    description: 'Оставьте свои контакты и мы свяжемся с Вами'
  },
  menu: {
    about: 'О нас',
    price: 'Цены',
    contacts: 'Контакты',
    tickets: 'Билеты',
  },
  about: {
    title: 'О нас'
  },
  price: {
    title: 'Цены',
    card: {
      title: 'Базовый',
      categoryAmkpp: 'Категория "А" МКПП',
      categoryBmkpp: 'Категория "B" МКПП',
      categoryBakpp: 'Категория "B" AКПП',
      length12: '12 недель',
      length10: '10 недель',
      standard: 'Вождение "стандарт"',
      rassr: 'Возможность оплаты в рассрочку',
      price38: '38 000 р + ГСМ',
      price45: '45 000 р + ГСМ',
      price50: '50 000 р + ГСМ',
      price35: '35 000 р + ГСМ',
      action: 'Записаться'
    },
    card_dop: {
      mkpp: 'МКПП',
      akpp: 'АКПП',
      one: {
        title: '1 занятие',
        priceMkpp: '2000 р',
        priceAkpp: '2500 р',
      },
      three: {
        title: '3 занятия',
        priceMkpp: '5000 р',
        priceAkpp: '6000 р',
      },
      five: {
        title: '5 занятий',
        priceMkpp: '7500 р',
        priceAkpp: '9000 р',
      }
    }
  },
  callback: {
    title: 'Обратный звонок',
    placeholder: {
      city: 'Город',
      name: 'Имя',
      phone: 'Телефон',
      contacts: 'Контакты (Telegram, WhatsApp, E-mail)',
      message: 'Сообщение'
    },
    baseMessages: {
      bMkpp: 'Здравствуйте! Я хотел(-а) бы записаться на курсы вождения по программе "Категория B МКПП"',
      bAkpp: 'Здравствуйте! Я хотел(-а) бы записаться на курсы вождения по программе "Категория B АКПП"',
      aMkpp: 'Здравствуйте! Я хотел(-а) бы записаться на курсы вождения по программе "Категория A МКПП"',
      dop1Mkpp: 'Здравствуйте! Я хотел(-а) бы записаться на 1 доп. занятие по вождению на МКПП',
      dop1Akpp: 'Здравствуйте! Я хотел(-а) бы записаться на 1 доп. занятие по вождению на АКПП',
      dop3Mkpp: 'Здравствуйте! Я хотел(-а) бы записаться на 3 доп. занятия по вождению на МКПП',
      dop3Akpp: 'Здравствуйте! Я хотел(-а) бы записаться на 3 доп. занятия по вождению на АКПП',
      dop5Mkpp: 'Здравствуйте! Я хотел(-а) бы записаться на 5 доп. занятий по вождению на МКПП',
      dop5Akpp: 'Здравствуйте! Я хотел(-а) бы записаться на 5 доп. занятий по вождению на АКПП',
    },
    submit: 'Оставить заявку',
    error: 'Не удалось оставить заявку',
    success: 'Заявка успешно отправлена',
  },
  contacts: {
    title: 'Контакты'
  }
}
