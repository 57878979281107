<template>
  <div class="d-flex flex-wrap justify-content-center">
    <div :class="getQuestionClass(i)" class="question-button font-1" v-for="q, i in questionStates" @click="onClick(i)">
      <b>{{ i + 1 }}</b>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import QuestionState from "../model/QuestionState";

@Component
export default class QuestionLine extends Vue {
  @Prop({type: Array, required: true})
  questionStates!: QuestionState[]

  @Prop({type: Number, required: false})
  current!: number | null

  onClick(i: number) {
    if (this.isClickable(i)) {
      this.$emit('click', i)
    }
  }

  isClickable(i: number): boolean {
    if (i < 0 || i > this.questionStates.length) return false

    let qs = this.questionStates[i]
    return qs.status != null || i <= 0 || this.questionStates[i - 1].status != null
  }

  getQuestionClass(i: number) {
    if (i >= this.questionStates.length) return {}

    let qs = this.questionStates[i]
    return {
      'right': qs.status == 'right',
      'wrong': qs.status == 'wrong',
      'current': i == this.current,
      'clickable': this.isClickable(i)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style";

.question-button {
  width: 24px;
  padding: 4px 0 0 0;
  text-align: center;
  cursor: default;
  font-size: 12px;
  margin: 3px;
  border-radius: 8px;
  background-color: #e7e7e7;
  @include transition($btn-transition);

  &.right {
    background-color: $primary;
    color: $on-primary;
  }

  &.wrong {
    background-color: $error;
    color: $on-error;
  }

  &.current:not(.wrong) {
    border: $primary solid 1px;
  }

  &.current.right {
    border: $secondary solid 1px;
  }

  &.clickable {
    cursor: pointer;
  }
}

</style>
