import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PriceView from "@/components/PriceView.vue";
import CallbackView from "@/components/CallbackView.vue";
import ContactsPage from "@/components/ContactsPage.vue";
import TicketPage from "@/views/TicketPage.vue";
import TestsPage from "@/views/Tickets.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/price',
    name: 'price',
    component: PriceView,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsPage,
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TestsPage,
  },
  {
    path: '/ticket/:id',
    name: 'ticket',
    component: TicketPage,
    props: true,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {

})

export default router
