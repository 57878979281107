import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import './assets/style.scss'
import VueI18n from "vue-i18n";
import locale from "@/locale";

Vue.config.productionTip = false

Vue.use(VueI18n)

let vue = new Vue({
  router,
  store,
  i18n: locale(),
  render: function (h) { return h(App) }
})

//vue.$router.afterEach(() => {
  //document.title = 'Республиканская Автошкола'
//})

vue.$mount('#app')

